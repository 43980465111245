import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";
import { Link } from "react-router-dom";
import Button from "../Admin/AdminPenTalk/Button/Button";
import CatagoryBar from "./CatagoryBar";
import AxiosServices from "../../Services/AxiosServices";

const PenTalk = () => {
  const [visibleBlogs, setVisibleBlogs] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [categories, setCategories] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const axiosService = new AxiosServices();

  const fetchCategoriesAndBlogs = async (page = 1) => {
    setError(null);
    try {
      const categoryResponse = await axiosService.get(
        "PenTalk/get-categories/0"
      );
      setCategories(categoryResponse.data);

      const blogResponse = await axiosService.get(
        `/PenTalk/paginated_blogs?pageNumber: ${page}&
          pageSize: ${pageSize}&categoryId=0`
      );

      setBlogData((prevData) => [
        ...prevData,
        ...blogResponse.data.sort(
          (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
        ),
      ]);
      setLoading(false);

      const totalBlogsCount = categoryResponse.data.reduce(
        (sum, category) => sum + (category.blogsCount || 0),
        0
      );
      setVisibleBlogs(totalBlogsCount);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoriesAndBlogs(currentPage);
  }, []);

  const loadMoreBlogs = () => {
    setCurrentPage((prev) => {
      const newPage = prev + 1;
      fetchCategoriesAndBlogs(newPage);
      return newPage;
    });
    window.scrollTo(document.body.scrollHeight, document.body.scrollHeight);
  };

  const getExcerpt = (content) => {
    const div = document.createElement("div");
    div.innerHTML = content;

    let plainTextContent = div.textContent || div.innerText;

    const firstBreak = plainTextContent.indexOf("\n");
    if (firstBreak !== -1) {
      return plainTextContent.slice(0, firstBreak);
    } else {
      return plainTextContent.length > 100
        ? plainTextContent.slice(0, 250) + "..."
        : plainTextContent;
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <BreadcrumbHeader />
      <div className="container topMargin">
        <div className="row">
          <div className="col-md-8">
            {blogData.length === 0 ? (
              <p
                className="text-center"
                style={{ marginTop: "100px", color: "gray", fontSize: "25px" }}
              >
                No Blogs
              </p>
            ) : (
              <>
                {loading ? (
                  <p>Loading blogs...</p>
                ) : (
                  blogData.slice(0, visibleBlogs).map((blog) => (
                    <div
                      key={blog.blogId}
                      className="section-blog-title topTextPenTalk"
                    >
                      <h2>{blog.title}</h2>
                      <p>
                        Posted on{" "}
                        {kendo.toString(
                          new Date(blog.updatedDate),
                          "MMMM dd yyyy "
                        )}{" "}
                        |{" "}
                        {
                          categories.find(
                            (category) =>
                              category.categoryId === blog.categoryId
                          )?.categoryName
                        }
                      </p>
                      <p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getExcerpt(blog.content),
                          }}
                        />
                        <br />
                        <Link
                          to={`/PenTalkDetails/${blog.categoryId}/${blog.blogId}`}
                          className="readMoreStyle"
                        >
                          [Read More]
                        </Link>
                      </p>
                    </div>
                  ))
                )}
              </>
            )}
            {blogData.length < visibleBlogs && blogData !== null && (
              <div className="loadmoreblogs">
                <Button onClick={loadMoreBlogs} name={"Load More"} />
              </div>
            )}
          </div>
          <div className="col-md-4 categorybar">
            <CatagoryBar categories={categories} />
          </div>
        </div>
      </div>
      <Scroll />
      <Footer>
        <p style={{ textAlign: "center", margin: "20px 0" }}>
          &copy; 2024 Your Company. All rights reserved.
        </p>
      </Footer>
    </div>
  );
};

export default PenTalk;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";
import Button from "../Admin/AdminPenTalk/Button/Button";
import CatagoryBar from "./CatagoryBar";
import AxiosServices from "../../Services/AxiosServices";

const PenTalkCategory = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [visibleBlogs, setVisibleBlogs] = useState();

  const axiosService = new AxiosServices();

  const fetchCategoriesAndBlogs = async (page = 1, reset = false) => {
    setError(null);
    try {
      if (reset) setBlogData([]);

      const categoryResponse = await axiosService.get(
        "PenTalk/get-categories/0"
      );
      setCategories(categoryResponse.data);

      const blogResponse = await axiosService.get(
        `/PenTalk/paginated_blogs?pageNumber: ${page}&
          pageSize: ${pageSize}&categoryId=${id}`
      );

      setBlogData((prevData) =>
        reset
          ? blogResponse.data
          : [
              ...prevData,
              ...blogResponse.data.sort(
                (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
              ),
            ]
      );
      const categoriesCount = categoryResponse.data.find(
        (cat) => cat.categoryId === parseInt(id)
      )?.blogsCount;

      setVisibleBlogs(categoriesCount);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    setLoading(true);
    fetchCategoriesAndBlogs(1, true); 
  }, [id]);

  const loadMoreBlogs = () => {
    setCurrentPage((prev) => {
      const newPage = prev + 1;
      fetchCategoriesAndBlogs(newPage);
      return newPage;
    });
    window.scrollTo(document.body.scrollHeight, document.body.scrollHeight);
  };

  const getExcerpt = (content) => {
    const div = document.createElement("div");
    div.innerHTML = content;

    let plainTextContent = div.textContent || div.innerText;

    const firstBreak = plainTextContent.indexOf("\n");
    if (firstBreak !== -1) {
      return plainTextContent.slice(0, firstBreak);
    } else {
      return plainTextContent.length > 100
        ? plainTextContent.slice(0, 500) + "..."
        : plainTextContent;
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <BreadcrumbHeader />
      <div className="container topMargin">
        <div className="row">
          <div className="col-md-8">
            {blogData.length === 0 ? (
              <p
                className="text-center"
                style={{ marginTop: "100px", fontSize: "25px" }}
              >
                No Blogs
              </p>
            ) : (
              <>
                {blogData.map((blog) => (
                  <div
                    key={blog.blogId}
                    className="section-blog-title topTextPenTalk"
                  >
                    <h2>{blog.title}</h2>
                    <p>
                      Posted on{" "}
                      {kendo.toString(
                        new Date(blog.updatedDate),
                        "MMMM dd yyyy "
                      )}{" "}
                      |{" "}
                      {
                        categories.find(
                          (cat) => cat.categoryId === blog.categoryId
                        )?.categoryName
                      }
                    </p>
                    <p>
                      {getExcerpt(blog.content)} <br />
                      <Link
                        to={`/PenTalkDetails/${blog.categoryId}/${blog.blogId}`}
                        className="readMoreStyle"
                      >
                        [Read More]
                      </Link>
                    </p>
                  </div>
                ))}
              </>
            )}
            {blogData.length < visibleBlogs && blogData !== null && (
              <div className="loadmoreblogs">
                <Button onClick={loadMoreBlogs} name="Load More" />
              </div>
            )}
          </div>
          <div className="col-md-4 categorybar">
            <CatagoryBar categories={categories} />
          </div>
        </div>
      </div>
      <Scroll />
      <Footer>
        <p style={{ textAlign: "center", margin: "20px 0" }}>
          &copy; 2024 Your Company. All rights reserved.
        </p>
      </Footer>
    </div>
  );
};

export default PenTalkCategory;

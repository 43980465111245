import React from "react";
import { KendoEditor } from "../../../../Kendo/kendo_editor";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";

export default function AddBlogModal({
  blog,
  handleCloseModal,
  handleChange,
  handlekendochange,
  handleSubmit,
  editIndex,
  titleChange,
}) {
  return (
    <div
      className="modal fade Mymodal"
      id="addBlogModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog custom-modal " role="document">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">
              {editIndex !== null ? "Edit Blog" : "Add Blog"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <section className="contact-area">
              <div className="row fadeInUp">
                <form className="contact-form" autoComplete="off">
                  <div className="col-md-12">
                    <div className="contact-form">
                      <div className="row">
                        <div className={"form-group col-md-12"}>
                          <TextInput
                            type={"text"}
                            onChange={handleChange}
                            value={blog.title}
                            name="title"
                            label="Title"
                            onBlur={titleChange}
                          />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Description
                            <span className="text-danger">*</span>
                          </label>

                          <KendoEditor
                            name={"description"}
                            height={600}
                            value={blog.content}
                            onChange={(e) => handlekendochange(e)}
                          />

                          <span className="text-danger" />
                        </div>
                        <div className="col-md-12 text-right">
                          <Button
                            name={"Cancel"}
                            onClick={handleCloseModal}
                            dismiss={"modal"}
                          />
                          <Button
                            name={editIndex !== null ? "Update" : "Submit"}
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import { KendoEditor } from "../../../../Kendo/kendo_editor";
// import TextInput from "../TextInput/TextInput";
// import Button from "../Button/Button";
// import Swal from "sweetalert2";
// import AxiosServices from "../../../../Services/AxiosServices";

// export default function AddBlogModal() {
//   const [activeTab, setActiveTab] = useState();
//   const [filteredEntries, setFilteredEntries] = useState([]);

//   const [blog, setBlog] = useState({
//     title: "",
//     content: "",
//     categoryId: "",
//   });
//   const [entries, setEntries] = useState([]);
//   const [errors, setErrors] = useState({ title: "", content: "", axios: "" });
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const queryParams = new URLSearchParams(window.location.search);
//   const paramId = queryParams.get("id");
//   const paramCategory = queryParams.get("category");

//   const axiosService = new AxiosServices();
//   const fetchCategoriesAndBlogs = async (paramCategory) => {
//     try {
//       const categoryResponse = await axiosService.get(
//         `PenTalk/get-categories/0`
//       );
//       const fetchedCategories = categoryResponse.data;
//       setCategories(fetchedCategories);

//       const initialActiveTab = paramCategory
//         ? fetchedCategories.find(
//             (category) => category.categoryId === paramCategory
//           )?.categoryName
//         : fetchedCategories[0]?.categoryName;

//       setActiveTab(initialActiveTab);

//       const blogResponse = await axiosService.get("PenTalk/get-blogs");
//       const allEntries = blogResponse.data;

//       setEntries(allEntries);

//       setFilteredEntries(
//         allEntries.filter(
//           (entry) =>
//             entry.categoryId ===
//             (paramCategory || fetchedCategories[0].categoryId)
//         )
//       );

//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   function titleChange() {
//     var title = $("#title").val();
//     if (title == "") {
//       document.getElementById("title").style.border = "1px solid #dd3535";
//     } else {
//       document.getElementById("title").style.border = "";
//     }
//   }

//   const fetchBlogByid = async (id) => {
//     const response = await axiosService.get("PenTalk/get-blogs/" + id);

//     setBlog(response.data[0]);
//   };

//   useEffect(() => {
//     fetchCategoriesAndBlogs();

//     if (paramId != "0") {
//       fetchBlogByid(paramId);
//     }
//   }, []);

//   const handleCloseModal = () => {
//     setBlog({
//       blogId: "",
//       title: "",
//       content: "",
//       categoryId: "",
//     });
//     const editorDiv = document.getElementsByClassName("k-editor")[0];
//     if (editorDiv) {
//       editorDiv.style.border = "";
//     }
//     const inputDiv = document.getElementById("title");
//     if (inputDiv) {
//       inputDiv.style.border = "";
//     }

//     setErrors({ title: "", content: "" });
//   };

//   const validateForm = () => {
//     const validationErrors = { title: "", content: "" };
//     let isValid = true;

//     if (!blog.title.trim()) {
//       validationErrors.title = "Title is required.";
//       const editorDiv = document.getElementById("title");
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!blog.content.trim()) {
//       validationErrors.content = "Content is required.";
//       const editorDiv = document.getElementsByClassName("k-editor")[0];
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!isValid) {
//       Swal.fire({
//         icon: "error",
//         title: "Please Fill all Required Fields",
//         position: "top-end",
//         toast: true,
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         customClass: {
//           container: "custom-swal-container",
//         },
//       });
//     }
//     setErrors(validationErrors);
//     return isValid;
//   };
//   const sendMessageToParent = () => {
//     if (window.opener) {
//       window.opener.postMessage("callParentFunction", window.location.origin);
//     } else {
//       console.warn("No parent window found");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     const savedBy = "1";

//     const categoryId = paramCategory;

//     const blogData = {
//       ...blog,
//       categoryId,
//       blogId: parseInt(paramId),
//     };

//     try {
//       if (blogData.blogId > 0) {
//         const editcheck = await axiosService.post(
//           `PenTalk/save-blog?savedBy=${savedBy}`,
//           blogData
//         );
//         if (editcheck) {
//           setTimeout(function () {
//             sendMessageToParent();
//             window.close();
//           }, 800);
//           fetchCategoriesAndBlogs(categoryId);
//         }
//       } else {
//         const savecheck = await axiosService.post(
//           `PenTalk/save-blog?savedBy=${savedBy}`,
//           blogData
//         );
//         if (savecheck) {
//           setTimeout(function () {
//             sendMessageToParent();
//             window.close();
//           }, 800);
//           fetchCategoriesAndBlogs(categoryId);
//         }
//       }

//       Swal.fire({
//         icon: "success",
//         title: paramId > 0 ? "Updated Successfully" : "Added Successfully",
//         position: "top-end",
//         toast: true,
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         customClass: {
//           container: "custom-swal-container",
//         },
//       });
//       handleCloseModal();
//     } catch (error) {
//       console.error("Error saving blog:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Failed to save or update blog",
//         position: "top-end",
//         toast: true,
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         customClass: {
//           container: "custom-swal-container",
//         },
//       });
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setBlog({ ...blog, [name]: value });
//     const editorDiv = document.getElementById(name);
//     if (!value.trim()) {
//       editorDiv.style.border = "1px solid #dd3535";
//     } else {
//       editorDiv.style.border = "";
//     }
//   };

//   const handlekendochange = (e) => {
//     setBlog((prevState) => ({
//       ...prevState,
//       content: e,
//     }));

//     const editorDiv = document.getElementsByClassName("k-editor")[0];
//     if (e.trim()) {
//       editorDiv.style.border = "";
//     }
//   };

//   return (
//     <>
//       <section className="container topMargin">
//         <div className="section-title">
//           <h2>{paramId > 0 ? "Edit Blog" : "Add Blog"}</h2>
//         </div>
//         <div className="row fadeInUp">
//           <form
//             className="contact-form"
//             onSubmit={handleSubmit}
//             autoComplete="off"
//           >
//             <div className="col-md-12">
//               <div className="contact-form">
//                 <div className="row">
//                   <div className={"form-group col-md-12"}>
//                     <TextInput
//                       type={"text"}
//                       onChange={handleChange}
//                       value={blog.title}
//                       name="title"
//                       label="Title"
//                       onBlur={titleChange}
//                     />
//                   </div>
//                   <div className={`form-group col-md-12`}>
//                     <label>
//                       Description
//                       <span className="text-danger">*</span>
//                     </label>

//                     <KendoEditor
//                       name={"description"}
//                       height={600}
//                       value={blog.content}
//                       onChange={(e) => handlekendochange(e)}
//                     />

//                     <span className="text-danger" />
//                   </div>
//                   <div
//                     className="col-md-12 text-right"
//                     style={{ marginBottom: "20px" }}
//                   >
//                     <Button
//                       name={"Cancel"}
//                       onClick={handleCloseModal}
//                       dismiss={"modal"}
//                     />
//                     <Button
//                       name={paramId > 0 ? "Update" : "Submit"}
//                       onClick={handleSubmit}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </section>
//     </>
//   );
// }

import Axios from "axios";
import { GetCookie } from "../common";

// DEV
// export const baseUrl = "http://localhost:47189/api/";

// QA
//export const baseUrl = "https://qaapi.pensoftware.io/api/";

// AZURE QA
//export const baseUrl="https://pensoftwareqaapi.azurewebsites.net/api/";

// Production
//export const baseUrl="https://api.pensoftware.io/api/";

export const baseUrl = window.global.API_BASE_URL;
Axios.defaults.baseURL = baseUrl;

const GetHeader = () => {
  return {
    Authorization: "Bearer " + GetCookie("_Token"),
  };
};

export default class AxiosServices {
  post(url, data, Header = null) {
    Header = GetHeader();

    return Axios.post(url, data, {
      headers: Header,
    });
  }
  get(url, header = null) {
    header = GetHeader();
    return Axios.get(url, {
      headers: header,
    });
  }
}

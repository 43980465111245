import React, { useEffect, useState } from "react";
import TextInput from "../../AdminPenTalk/TextInput/TextInput";
import Button from "../../AdminPenTalk/Button/Button";
import { KendoDropdown } from "../../../../Kendo/kendo_dropdown";
import { KendoEditor } from "../../../../Kendo/kendo_editor";

const AddOpportunityModal = ({
  opportunity,
  handleCloseModal,
  errors,
  handleChange,
  handlekendochange,
  handleWorktypechange,
  handleTextareachange,
  handleEmptypechange,
  handleSubmit,
  editIndex,
}) => {
  return (
    <div
      className="modal fade Mymodal "
      id="addopportunitymodal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog custom-modal" role="document">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">
              {editIndex !== null ? "Edit Opportunity" : "Add Opportunity"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <section className="contact-area">
              <div className="row fadeInUp">
                <form className="contact-form" autoComplete="off">
                  <div className="col-md-12">
                    <div className="contact-form">
                      <div className="row">
                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="position"
                            value={opportunity.position}
                            onChange={handleChange}
                            label="Position"
                          />
                        </div>
                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="location"
                            value={opportunity.location}
                            onChange={handleChange}
                            label="Location"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Work Place Type
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            className="kendo-dropdown-wrapper"
                            style={{
                              border: errors.workType
                                ? "1px solid #dd3535"
                                : "",
                              borderRadius: errors.workType ? "8px" : "",
                            }}
                          >
                            <KendoDropdown
                              name="workType"
                              data={[
                                { id: " ", value: " " },
                                { id: "On-Site", value: "On-Site" },
                                { id: "Off-Site", value: "Off-Site" },
                                { id: "Remote", value: "Remote" },
                              ]}
                              dataTextField="value"
                              dataValueField="id"
                              value={opportunity.workType}
                              onChange={handleWorktypechange}
                              className="form-control worktype"
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Employment Type
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            className="kendo-dropdown-wrapper"
                            style={{
                              border: errors.empType ? "1px solid #dd3535" : "",
                              borderRadius: errors.empType ? "8px" : "",
                            }}
                          >
                            <KendoDropdown
                              name="empType"
                              data={[
                                { id: " ", value: " " },
                                { id: "Full-time", value: "Full-time" },
                                { id: "Part-time", value: "Part-time" },
                                { id: "Internship", value: "Internship" },
                                { id: "Contract", value: "Contract" },
                              ]}
                              dataTextField="value"
                              dataValueField="id"
                              value={opportunity.empType}
                              onChange={handleEmptypechange}
                              className="form-control emptype"
                            />
                          </div>
                        </div>

                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="contractTerm"
                            value={opportunity.contractTerm}
                            onChange={handleChange}
                            label="Contract Term"
                          />
                        </div>
                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="experience"
                            value={opportunity.experience}
                            onChange={handleChange}
                            label="Experience"
                            className="form-control"
                          />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Summary
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            name={"summary"}
                            id="summary"
                            style={{ height: "100px", whiteSpace: "pre-wrap" }}
                            className="textarea"
                            value={opportunity.summary}
                            onChange={handleTextareachange}
                          />
                          <span className="text-danger" />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Required Skills
                            <span className="text-danger">*</span>
                          </label>
                          <KendoEditor
                            name={"requiredSkills"}
                            height={600}
                            value={opportunity.requiredSkills}
                            onChange={(e) => handlekendochange(e)}
                          />
                          <span className="text-danger" />
                        </div>
                        <div className="col-md-12 text-right">
                          <Button
                            name={"Cancel"}
                            onClick={handleCloseModal}
                            dismiss={"modal"}
                          />
                          <Button
                            name={editIndex !== null ? "Update" : "Submit"}
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOpportunityModal;

// import React, { useState, useEffect } from "react";
// import TextInput from "../../AdminPenTalk/TextInput/TextInput";
// import Button from "../../AdminPenTalk/Button/Button";
// import { KendoDropdown } from "../../../../Kendo/kendo_dropdown";
// import { KendoEditor } from "../../../../Kendo/kendo_editor";
// import AxiosServices from "../../../../Services/AxiosServices";
// import Swal from "sweetalert2";

// const AddOpportunityModal = () => {
//   const [opportunity, setOpportunity] = useState({
//     opportunityId: "",
//     position: "",
//     location: "",
//     workType: "",
//     empType: "",
//     contractTerm: "",
//     summary: "",
//     experience: "",
//     requiredSkills: "",
//   });
//   const [loading, setLoading] = useState(true);
//   const [errors, setErrors] = useState({
//     opportunityId: "",
//     position: "",
//     location: "",
//     workType: "",
//     empType: "",
//     contractTerm: "",
//     summary: "",
//     experience: "",
//     requiredSkills: "",
//   });
//   const queryParams = new URLSearchParams(window.location.search);
//   const paramValue = queryParams.get("id");

//   const axiosService = new AxiosServices();

//   const fetchOppurtunities = async () => {
//     try {
//       const opportunityResponse = await axiosService.get(
//         `Oppurtunities/get-oppourtunities/${paramValue}`
//       );
//       setOpportunity(opportunityResponse.data[0]);
//     } catch (error) {
//       console.error("Error fetching opportunities:", error);
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     if (paramValue > 0) {
//       fetchOppurtunities();
//     }
//   }, []);

//   const validateForm = () => {
//     const validationErrors = {
//       opportunityId: "",
//       position: "",
//       location: "",
//       workType: "",
//       empType: "",
//       contractTerm: "",
//       summary: "",
//       experience: "",
//       requiredSkills: "",
//     };
//     let isValid = true;

//     const checkField = (field, fieldName) => {
//       if (!field || !field.trim()) {
//         validationErrors[fieldName] = `${fieldName} is required.`;
//         return false;
//       }
//       return true;
//     };

//     if (!checkField(opportunity.position, "position")) {
//       const editorDiv = document.getElementById("position");
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.location, "location")) {
//       const editorDiv = document.getElementById("location");
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.workType, "workType")) {
//       const editorDiv = document.getElementsByClassName("worktype")[0];
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.empType, "empType")) {
//       const editorDiv = document.getElementsByClassName("emptype")[1];
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.contractTerm, "contractTerm")) {
//       const editorDiv = document.getElementById("contractTerm");
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.experience, "experience")) {
//       const editorDiv = document.getElementById("experience");
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.summary, "summary")) {
//       const editorDiv = document.getElementById("summary");
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }
//     if (!checkField(opportunity.requiredSkills, "requiredSkills")) {
//       const editorDiv = document.getElementsByClassName("k-editor")[0];
//       if (editorDiv) {
//         editorDiv.style.border = "1px solid #dd3535";
//       }
//       isValid = false;
//     }

//     if (!isValid) {
//       Swal.fire({
//         icon: "error",
//         title: "Please fill the mandatory fields",
//         position: "top-end",
//         toast: true,
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         customClass: { container: "custom-swal-container" },
//       });
//     }

//     setErrors(validationErrors);
//     return isValid;
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setOpportunity((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));

//     const inputDiv = document.getElementById(name);
//     if (inputDiv && value.trim()) {
//       inputDiv.style.border = "";
//     } else {
//       inputDiv.style.border = "1px solid #dd3535";
//     }
//   };
//   const sendMessageToParent = () => {
//     if (window.opener) {
//       window.opener.postMessage("callParentFunction", window.location.origin);
//     } else {
//       console.warn("No parent window found");
//     }
//   };

//   const handleWorktypechange = (e) => {
//     setOpportunity((prevState) => ({
//       ...prevState,
//       workType: e,
//     }));
//     if (e === " ") {
//       setErrors({ ...errors, workType: "requird" });
//     } else {
//       setErrors({ ...errors, workType: "" });
//     }
//   };

//   const handleEmptypechange = (e) => {
//     setOpportunity((prevState) => ({
//       ...prevState,
//       empType: e,
//     }));

//     if (e === " ") {
//       setErrors({ ...errors, empType: "requird" });
//     } else {
//       setErrors({ ...errors, empType: "" });
//     }
//   };

//   const handleTextareachange = (e) => {
//     const { name, value } = e.target;
//     setOpportunity((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));

//     const inputDiv = document.getElementById(name);
//     if (inputDiv && value.trim()) {
//       inputDiv.style.border = "";
//     } else {
//       inputDiv.style.border = "1px solid #dd3535";
//     }
//   };

//   const handlekendochange = (e) => {
//     setOpportunity((prevState) => ({
//       ...prevState,
//       requiredSkills: e,
//     }));

//     const editorDiv = document.getElementsByClassName("k-editor")[0];
//     if (e.trim()) {
//       editorDiv.style.border = "";
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validateForm() === false) {
//       return;
//     }
//     const savedBy = "1";
//     const opportunityData = {
//       ...opportunity,
//       opportunitiesId: "0",
//     };

//     try {
//       if (paramValue > 0) {
//         opportunityData.opportunitiesId = paramValue;
//         await axiosService.post(
//           `Oppurtunities/save-oppourtunities?savedBy=${savedBy}`,
//           opportunityData
//         );
//         Swal.fire({
//           icon: "success",
//           title: "Updated Successfully",
//           position: "top-end",
//           toast: true,
//           showConfirmButton: false,
//           timer: 3000,
//           timerProgressBar: true,
//           customClass: { container: "custom-swal-container" },
//         });

//         setTimeout(function () {
//           sendMessageToParent();
//           window.close();
//         }, 800);
//       } else {
//         await axiosService.post(
//           `Oppurtunities/save-oppourtunities?savedBy=${savedBy}`,
//           opportunityData
//         );

//         Swal.fire({
//           icon: "success",
//           title: "Added Successfully",
//           position: "top-end",
//           toast: true,
//           showConfirmButton: false,
//           timer: 3000,
//           timerProgressBar: true,
//           customClass: { container: "custom-swal-container" },
//         });
//         setTimeout(function () {
//           sendMessageToParent();
//           window.close();
//         }, 800);
//       }
//       handleCloseModal();
//     } catch (error) {
//       console.error("Error saving opportunity:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Failed to save opportunity",
//         position: "top-end",
//         toast: true,
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//       });
//     }
//   };
//   const handleCloseModal = () => {
//     setOpportunity({
//       opportunityId: "",
//       position: "",
//       location: "",
//       workType: "",
//       empType: "",
//       contractTerm: "",
//       summary: "",
//       experience: "",
//       requiredSkills: "",
//     });

//     const editorDiv = document.getElementsByClassName("k-editor")[0];
//     if (editorDiv) {
//       editorDiv.style.border = "";
//     }

//     const position = document.getElementById("position");
//     if (position) {
//       position.style.border = "";
//     }

//     const location = document.getElementById("location");
//     if (location) {
//       location.style.border = "";
//     }

//     const contractTerm = document.getElementById("contractTerm");
//     if (contractTerm) {
//       contractTerm.style.border = "";
//     }

//     const experience = document.getElementById("experience");
//     if (experience) {
//       experience.style.border = "";
//     }

//     const summary = document.getElementById("summary");
//     if (summary) {
//       summary.style.border = "";
//     }
//     const selectDiv = document.getElementsByClassName("worktype")[0];
//     if (selectDiv) {
//       selectDiv.style.border = "";
//     }
//     const emptype = document.getElementsByClassName("emptype")[0];
//     if (emptype) {
//       emptype.style.border = "";
//     }

//     setErrors({
//       opportunityId: "",
//       position: "",
//       location: "",
//       workType: "",
//       empType: "",
//       contractTerm: "",
//       summary: "",
//       experience: "",
//       requiredSkills: "",
//     });
//   };
//   return (
//     <>
//       <section className="container topMargin">
//         <div className="section-title">
//           <h2>{paramValue > 0 ? "Edit Opportunity" : "Add Opportunity"}</h2>
//         </div>
//         <div className="row fadeInUp">
//           <form
//             className="contact-form"
//             onSubmit={handleSubmit}
//             autoComplete="off"
//           >
//             <div className="col-md-12">
//               <div className="contact-form">
//                 <div className="row">
//                   <div className={"form-group col-md-6"}>
//                     <TextInput
//                       type={"text"}
//                       name="position"
//                       value={opportunity.position}
//                       onChange={handleChange}
//                       label="Position"
//                     />
//                   </div>
//                   <div className={"form-group col-md-6"}>
//                     <TextInput
//                       type={"text"}
//                       name="location"
//                       value={opportunity.location}
//                       onChange={handleChange}
//                       label="Location"
//                     />
//                   </div>
//                   <div className="form-group col-md-6">
//                     <label>
//                       Work Place Type
//                       <span className="text-danger">*</span>
//                     </label>
//                     <div
//                       className="kendo-dropdown-wrapper"
//                       style={{
//                         border: errors.workType ? "1px solid #dd3535" : "",
//                         borderRadius: errors.workType ? "8px" : "",
//                       }}
//                     >
//                       <KendoDropdown
//                         name="workType"
//                         data={[
//                           { id: " ", value: " " },
//                           { id: "On-Site", value: "On-Site" },
//                           { id: "Off-Site", value: "Off-Site" },
//                           { id: "Remote", value: "Remote" },
//                         ]}
//                         dataTextField="value"
//                         dataValueField="id"
//                         value={opportunity.workType}
//                         onChange={handleWorktypechange}
//                         className="form-control worktype"
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group col-md-6">
//                     <label>
//                       Employment Type
//                       <span className="text-danger">*</span>
//                     </label>
//                     <div
//                       className="kendo-dropdown-wrapper"
//                       style={{
//                         border: errors.empType ? "1px solid #dd3535" : "",
//                         borderRadius: errors.empType ? "8px" : "",
//                       }}
//                     >
//                       <KendoDropdown
//                         name="empType"
//                         data={[
//                           { id: " ", value: " " },
//                           { id: "Full-time", value: "Full-time" },
//                           { id: "Part-time", value: "Part-time" },
//                           { id: "Internship", value: "Internship" },
//                           { id: "Contract", value: "Contract" },
//                         ]}
//                         dataTextField="value"
//                         dataValueField="id"
//                         value={opportunity.empType}
//                         onChange={handleEmptypechange}
//                         className="form-control emptype"
//                       />
//                     </div>
//                   </div>

//                   <div className={"form-group col-md-6"}>
//                     <TextInput
//                       type={"text"}
//                       name="contractTerm"
//                       value={opportunity.contractTerm}
//                       onChange={handleChange}
//                       label="Contract Term"
//                     />
//                   </div>
//                   <div className={"form-group col-md-6"}>
//                     <TextInput
//                       type={"text"}
//                       name="experience"
//                       value={opportunity.experience}
//                       onChange={handleChange}
//                       label="Experience"
//                       className="form-control"
//                     />
//                   </div>
//                   <div className={`form-group col-md-12`}>
//                     <label>
//                       Summary
//                       <span className="text-danger">*</span>
//                     </label>
//                     <textarea
//                       name={"summary"}
//                       id="summary"
//                       style={{ height: "100px", whiteSpace: "pre-wrap" }}
//                       className="textarea"
//                       value={opportunity.summary}
//                       onChange={handleTextareachange}
//                     />
//                     <span className="text-danger" />
//                   </div>
//                   <div className={`form-group col-md-12`}>
//                     <label>
//                       Required Skills
//                       <span className="text-danger">*</span>
//                     </label>
//                     <KendoEditor
//                       name={"requiredSkills"}
//                       height={600}
//                       value={opportunity.requiredSkills}
//                       onChange={(e) => handlekendochange(e)}
//                     />
//                     <span className="text-danger" />
//                   </div>
//                   <div
//                     className="col-md-12 text-right"
//                     style={{ marginBottom: "20px" }}
//                   >
//                     <Button
//                       name={"Cancel"}
//                       onClick={handleCloseModal}
//                       dismiss={"modal"}
//                     />
//                     <Button
//                       name={paramValue > 0 ? "Update" : "Submit"}
//                       onClick={handleSubmit}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </section>
//     </>
//   );
// };

// export default AddOpportunityModal;

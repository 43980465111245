import React, { useState, useEffect } from "react";
import Loader from "../../Common/Loader";
import { KendoGrid } from "../../../Kendo/kendo_grid";
import AddBlogModal from "./modal/AddModal";
import DeleteBlogModal from "./modal/DeleteBlogModal";
import Button from "./Button/Button";
import Swal from "sweetalert2";
import { Layout } from "../Layout/Layout";
import AxiosServices from "../../../Services/AxiosServices";

export default function AdminPenTalk() {
  const [activeTab, setActiveTab] = useState();
  const [filteredEntries, setFilteredEntries] = useState([]);

  const [blog, setBlog] = useState({
    title: "",
    content: "",
    categoryId: "",
  });
  const [entries, setEntries] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [errors, setErrors] = useState({ title: "", content: "", axios: "" });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const axiosService = new AxiosServices();
  const fetchCategoriesAndBlogs = async (selectedCategory = null) => {
    try {
      const categoryResponse = await axiosService.get(
        "PenTalk/get-categories/0"
      );
      const fetchedCategories = categoryResponse.data;
      setCategories(fetchedCategories);

      const initialActiveTab = selectedCategory
        ? fetchedCategories.find(
            (category) => category.categoryId === selectedCategory
          )?.categoryName
        : fetchedCategories[0]?.categoryName;

      setActiveTab(initialActiveTab);

      const blogResponse = await axiosService.get("PenTalk/get-blogs");
      const allEntries = blogResponse.data.sort(
        (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
      );
      setEntries(allEntries);

      setFilteredEntries(
        allEntries.filter(
          (entry) =>
            entry.categoryId ===
            (selectedCategory || fetchedCategories[0].categoryId)
        )
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function titleChange() {
    var title = $("#title").val();
    if (title == "") {
      document.getElementById("title").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("title").style.border = "";
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const selectedCategoryId = categories.find(
      (category) => category.categoryName === tab
    )?.categoryId;

    setFilteredEntries(
      entries.filter((entry) => entry.categoryId === selectedCategoryId)
    );
  };

  useEffect(() => {
    fetchCategoriesAndBlogs();
  }, []);

  const handleCloseModal = () => {
    setBlog({
      blogId: "",
      title: "",
      content: "",
      categoryId: "",
    });
    const editorDiv = document.getElementsByClassName("k-editor")[0];
    if (editorDiv) {
      editorDiv.style.border = "";
    }
    const inputDiv = document.getElementById("title");
    if (inputDiv) {
      inputDiv.style.border = "";
    }

    setEditIndex(null);
    setErrors({ title: "", content: "" });
  };

  const handleEditClick = (entry) => {
    setBlog({
      blogId: entry.blogId,
      title: entry.title,
      content: entry.content,
      categoryId: entry.categoryId,
    });
    setEditIndex(entry.blogId);
    $("#addBlogModal").modal("show");
  };

  const handleDeleteClick = (entry) => {
    setDeleteIndex(entry.blogId);
    $("#deleteBlogModal").modal("show");
  };

  const confirmDelete = async () => {
    if (deleteIndex !== null) {
      try {
        const deletedBy = "1";
        const deletecheck = await axiosService.get(
          `PenTalk/delete-blog/${deleteIndex}?deletedBy=${deletedBy}`
        );
        if (deletecheck) {
          setEntries((prevEntries) =>
            prevEntries.filter((entry) => entry.blogId !== deleteIndex)
          );
          setFilteredEntries((prevEntries) =>
            prevEntries.filter((entry) => entry.blogId !== deleteIndex)
          );
          Swal.fire({
            icon: "success",
            title: "Deleted Successfully",
            position: "top-end",
            toast: true,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: "custom-swal-container",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting blog:", error);
        Swal.fire({
          icon: "error",
          title: "Failed to delete blog",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "custom-swal-container",
          },
        });
      }
      setDeleteIndex(null);
      $("#deleteBlogModal").modal("hide");
    }
  };

  const validateForm = () => {
    const validationErrors = { title: "", content: "" };
    let isValid = true;

    if (!blog.title.trim()) {
      validationErrors.title = "Title is required.";
      const editorDiv = document.getElementById("title");
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!blog.content.trim()) {
      validationErrors.content = "Content is required.";
      const editorDiv = document.getElementsByClassName("k-editor")[0];
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "Please Fill all Required Fields",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          container: "custom-swal-container",
        },
      });
    }
    setErrors(validationErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const savedBy = "1";

    const categoryId = categories.find(
      (category) => category.categoryName === activeTab
    )?.categoryId;

    const blogData = {
      ...blog,
      categoryId,
      blogId: editIndex ? blog.blogId : "0",
    };

    try {
      if (editIndex !== null) {
        const editcheck = await axiosService.post(
          `PenTalk/save-blog?savedBy=${savedBy}`,
          blogData
        );
        if (editcheck) {
          fetchCategoriesAndBlogs(categoryId);
        }
      } else {
        const savecheck = await axiosService.post(
          `PenTalk/save-blog?savedBy=${savedBy}`,
          blogData
        );
        if (savecheck) {
          fetchCategoriesAndBlogs(categoryId);
        }
      }

      Swal.fire({
        icon: "success",
        title: editIndex ? "Updated Successfully" : "Added Successfully",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          container: "custom-swal-container",
        },
      });

      $("#addBlogModal").modal("hide");
      handleCloseModal();
    } catch (error) {
      console.error("Error saving blog:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to save or update blog",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          container: "custom-swal-container",
        },
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlog({ ...blog, [name]: value });
    const editorDiv = document.getElementById(name);
    if (!value.trim()) {
      editorDiv.style.border = "1px solid #dd3535";
    } else {
      editorDiv.style.border = "";
    }
  };

  const handlekendochange = (e) => {
    setBlog((prevState) => ({
      ...prevState,
      content: e,
    }));

    const editorDiv = document.getElementsByClassName("k-editor")[0];
    if (e.trim()) {
      editorDiv.style.border = "";
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Layout />
      <div className="container topMargin">
        <div className="categorytabs">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div className="section-title">
              <h2 style={{ marginTop: 30 }}>PENTalk</h2>
            </div>
          </div>
          <br />
          <ul className="nav nav-tabs" id="myTab">
            {categories.map((tab) => (
              <li
                key={tab.categoryId}
                className={`nav-item ${
                  activeTab === tab.categoryName ? "active" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    activeTab === tab.categoryName
                      ? "activeTabStyle"
                      : "tabStyle"
                  }`}
                  onClick={() => handleTabClick(tab.categoryName)}
                >
                  {tab.categoryName.charAt(0).toUpperCase() +
                    tab.categoryName.slice(1).replace(/_/g, " ")}
                  (
                  {
                    entries.filter((b) => b.categoryId === tab.categoryId)
                      .length
                  }
                  )
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          {categories.map((tab) => (
            <div
              key={tab.categoryId}
              className={`tab-pane ${
                activeTab === tab.categoryName ? "fade in active" : ""
              }`}
              id={tab.categoryName}
            >
              <div className="row tabheadingrow">
                <div className="col-xs-12 Blogadd">
                  <Button name={"Add Blog"} target={"#addBlogModal"} />
                </div>
              </div>
              {activeTab && (
                <KendoGrid
                  name={"grd-" + tab.categoryId}
                  data={filteredEntries}
                  schema={{
                    title: { type: "string" },
                  }}
                  columns={[
                    { field: "blogId", title: "Id", hidden: true },
                    { field: "title", title: "Title", width: 500 },

                    {
                      field: "updatedDate",
                      title: "Updated Date",
                      template: function (e) {
                        return kendo.toString(
                          new Date(e.updatedDate),
                          "MMM-dd-yyyy hh:mm tt"
                        );
                      },
                      width: 300,
                    },
                  ]}
                  filterable={true}
                  paggable={true}
                  sortable={true}
                  showOptions={true}
                  onEditClick={handleEditClick}
                  onDeleteClick={handleDeleteClick}
                  scrollable={true}
                  exportFileName={"Blogs.xlsx"}
                  refreshWhen={activeTab}
                />
              )}
            </div>
          ))}
        </div>

        <AddBlogModal
          blog={blog}
          editIndex={editIndex}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCloseModal={handleCloseModal}
          handlekendochange={handlekendochange}
          titleChange={titleChange}
        />
        <DeleteBlogModal confirmDelete={confirmDelete} />
      </div>
    </>
  );
}

// import React, { useState, useEffect, useRef } from "react";
// import Loader from "../../Common/Loader";
// import { KendoGrid } from "../../../Kendo/kendo_grid";
// import DeleteBlogModal from "./modal/DeleteBlogModal";
// import Button from "./Button/Button";
// import Swal from "sweetalert2";
// import { Layout } from "../Layout/Layout";
// import AxiosServices from "../../../Services/AxiosServices";

// export default function AdminPenTalk() {
//   let width = screen.availWidth - 50;
//   let height = screen.availHeight - 50;
//   var left = 25;
//   var top = 25;
//   var windowFeatures =
//     "width=" +
//     width +
//     ",height=" +
//     height +
//     ",scrollbars,status,resizable,left=" +
//     left +
//     ",top=" +
//     top +
//     "screenX=" +
//     left +
//     ",screenY=" +
//     top;

//   const handleNavigation = (id) => {
//     window.open(`/add-blog?category=${selCatId}&id=${id}`, "", windowFeatures);
//   };
//   const childWindowRef = useRef(null);

//   const [activeTab, setActiveTab] = useState();
//   const [selCatId, setSelCatId] = useState();
//   const [filteredEntries, setFilteredEntries] = useState([]);
//   const [entries, setEntries] = useState([]);
//   const [deleteIndex, setDeleteIndex] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const axiosService = new AxiosServices();
//   const fetchCategoriesAndBlogs = async (selectedCategory = null) => {
//     if (selectedCategory == null) {
//       selectedCategory = selCatId;
//     }
//     try {
//       const categoryResponse = await axiosService.get(
//         "PenTalk/get-categories/0"
//       );
//       const fetchedCategories = categoryResponse.data;
//       setCategories(fetchedCategories);

//       const initialActiveTab = selectedCategory
//         ? fetchedCategories.find(
//             (category) => category.categoryId === selectedCategory
//           )
//         : fetchedCategories[0];

//       setActiveTab(initialActiveTab.categoryName);
//       setSelCatId(initialActiveTab.categoryId);

//       const blogResponse = await axiosService.get("PenTalk/get-blogs");
//       const allEntries = blogResponse.data.sort(
//         (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
//       );
//       setEntries(allEntries);

//       setFilteredEntries(
//         allEntries.filter(
//           (entry) =>
//             entry.categoryId ===
//             (selectedCategory || fetchedCategories[0].categoryId)
//         )
//       );

//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//     const selectedCategoryId = categories.find(
//       (category) => category.categoryName === tab
//     )?.categoryId;

//     setFilteredEntries(
//       entries.filter((entry) => entry.categoryId === selectedCategoryId)
//     );

//     setSelCatId(selectedCategoryId);
//   };
//   const handleMessageFromChild = (event) => {
//     if (event.origin !== window.location.origin) {
//       console.warn("Message from untrusted origin:", event.origin);
//       return;
//     }

//     if (event.data === "callParentFunction") {
//       fetchCategoriesAndBlogs(selCatId);
//     }
//   };
//   useEffect(() => {
//     window.addEventListener("message", handleMessageFromChild);

//     return () => {
//       window.removeEventListener("message", handleMessageFromChild);
//     };
//   }, []);

//   useEffect(() => {
//     fetchCategoriesAndBlogs();
//   }, []);

//   const handleEditClick = (e) => {
//     window.open(
//       `/add-blog?category=${selCatId}&id=${e.blogId}`,
//       "",
//       windowFeatures
//     );
//   };

//   const handleDeleteClick = (entry) => {
//     setDeleteIndex(entry.blogId);
//     $("#deleteBlogModal").modal("show");
//   };

//   const confirmDelete = async () => {
//     if (deleteIndex !== null) {
//       try {
//         const deletedBy = "1";
//         const deletecheck = await axiosService.get(
//           `PenTalk/delete-blog/${deleteIndex}?deletedBy=${deletedBy}`
//         );
//         if (deletecheck) {
//           setEntries((prevEntries) =>
//             prevEntries.filter((entry) => entry.blogId !== deleteIndex)
//           );
//           setFilteredEntries((prevEntries) =>
//             prevEntries.filter((entry) => entry.blogId !== deleteIndex)
//           );
//           Swal.fire({
//             icon: "success",
//             title: "Deleted Successfully",
//             position: "top-end",
//             toast: true,
//             showConfirmButton: false,
//             timer: 3000,
//             timerProgressBar: true,
//             customClass: {
//               container: "custom-swal-container",
//             },
//           });
//         }
//       } catch (error) {
//         console.error("Error deleting blog:", error);
//         Swal.fire({
//           icon: "error",
//           title: "Failed to delete blog",
//           position: "top-end",
//           toast: true,
//           showConfirmButton: false,
//           timer: 3000,
//           timerProgressBar: true,
//           customClass: {
//             container: "custom-swal-container",
//           },
//         });
//       }
//       setDeleteIndex(null);
//       $("#deleteBlogModal").modal("hide");
//     }
//   };

//   return (
//     <>
//       {loading && <Loader />}
//       <Layout />
//       <div className="container topMargin">
//         <div className="categorytabs">
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               flexWrap: "wrap",
//             }}
//           >
//             <div className="section-title">
//               <h2 style={{ marginTop: 30 }}>PENTalk</h2>
//             </div>
//           </div>
//           <br />
//           <ul className="nav nav-tabs" id="myTab">
//             {categories.map((tab) => (
//               <li
//                 key={tab.categoryId}
//                 className={`nav-item ${
//                   activeTab === tab.categoryName ? "active" : ""
//                 }`}
//               >
//                 <a
//                   className={`nav-link ${
//                     activeTab === tab.categoryName
//                       ? "activeTabStyle"
//                       : "tabStyle"
//                   }`}
//                   onClick={() => handleTabClick(tab.categoryName)}
//                 >
//                   {tab.categoryName.charAt(0).toUpperCase() +
//                     tab.categoryName.slice(1).replace(/_/g, " ")}
//                   (
//                   {
//                     entries.filter((b) => b.categoryId === tab.categoryId)
//                       .length
//                   }
//                   )
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//         <div className="tab-content" id="myTabContent">
//           {categories.map((tab) => (
//             <div
//               key={tab.categoryId}
//               className={`tab-pane ${
//                 activeTab === tab.categoryName ? "fade in active" : ""
//               }`}
//               id={tab.categoryName}
//             >
//               <div className="row tabheadingrow">
//                 <div className="col-xs-12 Blogadd">
//                   <Button
//                     name={"Add Blog"}
//                     onClick={() => {
//                       handleNavigation(0, activeTab);
//                     }}
//                   />
//                 </div>
//               </div>
//               {activeTab && (
//                 <KendoGrid
//                   name={"grd-" + tab.categoryId}
//                   data={filteredEntries}
//                   schema={{
//                     title: { type: "string" },
//                   }}
//                   columns={[
//                     { field: "blogId", title: "Id", hidden: true },
//                     { field: "title", title: "Title", width: 500 },

//                     {
//                       field: "updatedDate",
//                       title: "Updated Date",
//                       template: function (e) {
//                         return kendo.toString(
//                           new Date(e.updatedDate),
//                           "MMM-dd-yyyy hh:mm tt"
//                         );
//                       },
//                       width: 300,
//                     },
//                   ]}
//                   filterable={true}
//                   paggable={true}
//                   sortable={true}
//                   showOptions={true}
//                   onEditClick={handleEditClick}
//                   onDeleteClick={handleDeleteClick}
//                   scrollable={true}
//                   exportFileName={"Blogs.xlsx"}
//                   refreshWhen={activeTab}
//                 />
//               )}
//             </div>
//           ))}
//         </div>

//         <DeleteBlogModal confirmDelete={confirmDelete} />
//       </div>
//     </>
//   );
// }

import "./KendoScripts/styles/kendo.common.min.css";
import "./KendoScripts/styles/kendo.material.min.css";
import "./KendoScripts/js/kendo.all.min.js";
import "./Components/StyleVT/styleVt.css";
import Home from "../src/Components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Ouruniverse from "../src/Components/OurUniverse/OurUniverse";
import DigitalTransformation from "../src/Components/Expertise/DigitalTransformation";
import OracleConsulting from "../src/Components/Expertise/OracleConsulting";
import SAPConsulting from "../src/Components/Expertise/SAPConsulting";
import ProfessionalStaffing from "../src/Components/Expertise/ProfessionalStaffing";
import Excellence from "../src/Components/Excellance/Excellence";
import PenTalk from "./Components/PENTalk/PENTalk";
import PenTalkDetails from "./Components/PENTalk/PENTalkDetails";
import Connectwithus from "./Components/Connectwithus/Connectwithus";
import Dashboard from "./Components/Admin/Dashboard/Dashboard";
import ContactList from "./Components/Admin/Contact/ContactList";

import PenTalkCategory from "./Components/PENTalk/PENTalkCategory.js";
import AdminPenTalk from "./Components/Admin/AdminPenTalk/AdminPenTalk.js";
import Oppourtunities from "./Components/Oppourtunities/Oppourtunities";
import OpportunitiesAdmin from "./Components/Admin/OpportunityAdmin/OpportunitiesAdmin.js";
import OtpVerification from "./Components/Admin/Login/OtpVerification.js";
import LoginView from "./Components/Admin/Login/LoginView.js";
import ForgotPassword from "./Components/Admin/Login/ForgotPassWord.js";
import { useEffect, useState } from "react";
import { GetCookie } from "./common.js";
import Loader from "./Components/Common/Loader.js";
import AddOpportunityModal from "./Components/Admin/OpportunityAdmin/Modal/AddOpportunityModal.js";
import AddBlogModal from "./Components/Admin/AdminPenTalk/modal/AddModal.js";

function App() {
  const [validateUser, setValidateUser] = useState(true);

  useEffect(() => {
    let token = GetCookie("_Token");
    let url = window.location.href.toLowerCase();
    if (url.endsWith("/admin")) {
      if (token) {
        window.location.href = "/Opportunities-admin";
      }
    }

    if (url.includes("/adminpentalk") || url.includes("/opportunities-admin")) {
      if (!token) {
        window.location.href = "/admin";
      }
    }

    setTimeout(function () {
      setValidateUser(false);
    }, 800);
  }, []);

  if (validateUser) {
    return <Loader />;
  }
  return (
    <Router>
      <Routes>
        <Route path="/ContactList" element={<ContactList />}></Route>
        <Route path="/DashboardView" element={<Dashboard />}></Route>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="*" element={<Home />}></Route>
        <Route exact path="/OurUniverse" element={<Ouruniverse />}></Route>
        <Route
          exact
          path="/DigitalTransformation"
          element={<DigitalTransformation />}
        ></Route>
        <Route
          exact
          path="/OracleConsulting"
          element={<OracleConsulting />}
        ></Route>
        <Route exact path="/SAPConsulting" element={<SAPConsulting />}></Route>
        <Route
          exact
          path="/ProfessionalStaffing"
          element={<ProfessionalStaffing />}
        ></Route>
        <Route exact path="/Excellence" element={<Excellence />}></Route>
        <Route exact path="/Connectwithus" element={<Connectwithus />}></Route>
        <Route
          exact
          path="/PenTalkDetails/:category/:id"
          element={<PenTalkDetails />}
        ></Route>
        <Route
          exact
          path="/PenTalkCategory/:id"
          element={<PenTalkCategory />}
        ></Route>
        <Route exact path="/AdminPenTalk" element={<AdminPenTalk />}></Route>
        <Route exact path="/PenTalk" element={<PenTalk />}></Route>
        <Route
          exact
          path="/Oppourtunities"
          element={<Oppourtunities />}
        ></Route>
        <Route
          exact
          path="/Opportunities-admin"
          element={<OpportunitiesAdmin />}
        ></Route>
        <Route path="/add-opportunity" element={<AddOpportunityModal />} />
        <Route path="/add-blog" element={<AddBlogModal />} />
        <Route path="/admin" element={<LoginView />}></Route>
        <Route path="/ForgotView" element={<ForgotPassword />}></Route>
        <Route path="/OtpVerification" element={<OtpVerification />}></Route>
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect } from "react";
import { generateGuid } from "./kendo_common";
import $ from "jquery";

export const KendoEditor = ({
  name,
  imageBrowser,
  height,
  value,
  required,
  onValidate,
  onCursorPositionChange,
  onChange,
  refreshWhen,
}) => {
  var guid = generateGuid();

  useEffect(() => {
    if (!$(`#${guid}-${name}`).data("kendoEditor")) {
      $(`#${guid}-${name}`).kendoEditor({
        tools: [
          "bold",
          "italic",
          "underline",
          "undo",
          "redo",
          "strikethrough",
          "justifyLeft",
          "justifyCenter",
          "justifyRight",
          "justifyFull",
          //"insertImage",
          "insertUnorderedList",
          "insertOrderedList",
          "insertUpperRomanList",
          "insertLowerRomanList",
          "indent",
          "outdent",
          "subscript",
          "superscript",
          "tableWizard",
          "createTable",
          "addRowAbove",
          "addRowBelow",
          "addColumnLeft",
          "addColumnRight",
          "deleteRow",
          "deleteColumn",
          "mergeCellsHorizontally",
          "mergeCellsVertically",
          "splitCellHorizontally",
          "splitCellVertically",
          "tableAlignLeft",
          "tableAlignCenter",
          "tableAlignRight",
          "formatting",
          "cleanFormatting",
          "copyFormat",
          "applyFormat",
          "fontSize",
          "print",
          "ViewHtml",
          {
            name: "backColor",
            palette: [
              "#FFD5D7",
              "#FFFABB",
              "#FFFFFF",
              "#C8E1B6",
              "#95E89D",
              "#A2BBF1",
              "#94DDFD",
              "#EFE6F8",
              "#D4BDFF",
              "#FFD9B2",
              "#FFEECA",
              "#F9C9F1",
              "#FFECFC",
              "#CADCE0",
              "#E0ECE8",
              "#EDDBC2",
              "#E9E9E9",
              "#C4C4C4",

              "#FFC4C3",
              "#FFEEA9",
              "#FFFAC8",
              "#BEDEA7",
              "#85DE82",
              "#93B2F0",
              "#82D9FF",
              "#E6D9F7",
              "#C9B1EE",
              "#FFD299",
              "#FFEAD1",
              "#F5BBED",
              "#FFDCF5",
              "#BFD5D9",
              "#D7E4E2",
              "#E6CEA3",
              "#D9D9D9",
              "#B3B3B3",

              "#FEB2BA",
              "#FFED99",
              "#FDEDAB",
              "#B6D7A0",
              "#7CDA72",
              "#89A8E1",
              "#70D3FB",
              "#DCCBF4",
              "#C1A1EC",
              "#FFD390",
              "#FFDABE",
              "#F4ABD9",
              "#FEC9F8",
              "#B5CED2",
              "#CBDAD8",
              "#E6CBA8",
              "#D9D9D9",
              "#B3B3B3",

              "#FFA3A4",
              "#FEEA87",
              "#FEEDA6",
              "#ABD490",
              "#70D864",
              "#739FE8",
              "#71D2FF",
              "#D4BEE7",
              "#B993E8",
              "#FEC17E",
              "#FFD0AA",
              "#F39CE1",
              "#FFB4F7",
              "#AAC7CB",
              "#C2D9D3",
              "#E3BA9C",
              "#D1D1D1",
              "#ABABAB",

              "#FF9293",
              "#FEE777",
              "#FFE696",
              "#A4CE85",
              "#61D357",
              "#6B93E4",
              "#61CDFE",
              "#C9B0EA",
              "#AE86E6",
              "#FEB96E",
              "#FFBD8D",
              "#EE90DE",
              "#FCA3F6",
              "#A1C0C3",
              "#B7D2CB",
              "#DEB291",
              "#C8C8C8",
              "#A2A2A2",

              "#FF8184",
              "#FCE465",
              "#FFE189",
              "#9BCC73",
              "#59D148",
              "#5A89E1",
              "#4FC8FF",
              "#BFA4E2",
              "#A477E5",
              "#FFB55B",
              "#FFB2A1",
              "#E981DF",
              "#FE93F3",
              "#96B9BF",
              "#ADCBC3",
              "#DBA97D",
              "#BFBFBF",
              "#9A9A9A",

              "#FD726F",
              "#FCE352",
              "#FFDA78",
              "#91C66B",
              "#4ACC39",
              "#487FE1",
              "#40C2FE",
              "#B994E5",
              "#9A67E7",
              "#FFA850",
              "#FFAA6A",
              "#E874D1",
              "#FF81F5",
              "#8FB0B7",
              "#A1C5BB",
              "#DA9E72",
              "#B7B7B7",
              "#919191",

              "#FE5F60",
              "#FFDE45",
              "#FFD964",
              "#88C25A",
              "#3FC433",
              "#3B74DF",
              "#2FBDFF",
              "#AE87E0",
              "#905BDD",
              "#FEA139",
              "#FF9F5A",
              "#E664D1",
              "#FC71F4",
              "#80ABB1",
              "#96BEB3",
              "#D39667",
              "#AEAEAE",
              "#898989",

              "#FC504E",
              "#FFDA33",
              "#FFD552",
              "#81BB57",
              "#3AB630",
              "#3069DA",
              "#1DB7FF",
              "#A67ADC",
              "#8A4CDB",
              "#FF992A",
              "#FF9549",
              "#E455CB",
              "#FF60ED",
              "#78A3AA",
              "#8DB9AC",
              "#CF8E58",
              "#A6A6A6",
              "#808080",

              "#FF3D3D",
              "#FED823",
              "#FFD140",
              "#78B649",
              "#37A92D",
              "#2461D4",
              "#0CB2FF",
              "#9C6CD9",
              "#7E3ED8",
              "#FE9118",
              "#FF8B36",
              "#E246CB",
              "#FB50EC",
              "#6B9DA6",
              "#7FB4A4",
              "#CC834C",
              "#9D9D9D",
              "#787878",

              "#FF2B2B",
              "#FFD510",
              "#FECE2C",
              "#6DAB43",
              "#349A2C",
              "#205BC2",
              "#00ADFC",
              "#9061D2",
              "#7331DA",
              "#FD8908",
              "#FF8226",
              "#E237C4",
              "#FE3EE9",
              "#5E979E",
              "#75AD9C",
              "#C97A40",
              "#959595",
              "#6F6F6F",

              "#FF181B",
              "#F5D505",
              "#FEC821",
              "#679F3F",
              "#2E8E23",
              "#1E55BA",
              "#00A0E9",
              "#8952D4",
              "#692CC9",
              "#F97D00",
              "#FF7413",
              "#DF28C2",
              "#FA2EEF",
              "#588C97",
              "#6EA396",
              "#BE7437",
              "#8C8C8C",
              "#5E5E5E",

              "#FD0A0E",
              "#EBC500",
              "#FFC40E",
              "#60923B",
              "#2B8025",
              "#1E4DA9",
              "#0095D6",
              "#7F45CB",
              "#6627BF",
              "#E37800",
              "#FF6D06",
              "#D322B6",
              "#FC1DE8",
              "#55818A",
              "#639D8E",
              "#AF6C33",
              "#848484",
              "#000000",
            ],
          },
          {
            name: "foreColor",
            palette: [
              "#FFD5D7",
              "#FFFABB",
              "#FFFFFF",
              "#C8E1B6",
              "#95E89D",
              "#A2BBF1",
              "#94DDFD",
              "#EFE6F8",
              "#D4BDFF",
              "#FFD9B2",
              "#FFEECA",
              "#F9C9F1",
              "#FFECFC",
              "#CADCE0",
              "#E0ECE8",
              "#EDDBC2",
              "#E9E9E9",
              "#C4C4C4",

              "#FFC4C3",
              "#FFEEA9",
              "#FFFAC8",
              "#BEDEA7",
              "#85DE82",
              "#93B2F0",
              "#82D9FF",
              "#E6D9F7",
              "#C9B1EE",
              "#FFD299",
              "#FFEAD1",
              "#F5BBED",
              "#FFDCF5",
              "#BFD5D9",
              "#D7E4E2",
              "#E6CEA3",
              "#D9D9D9",
              "#B3B3B3",

              "#FEB2BA",
              "#FFED99",
              "#FDEDAB",
              "#B6D7A0",
              "#7CDA72",
              "#89A8E1",
              "#70D3FB",
              "#DCCBF4",
              "#C1A1EC",
              "#FFD390",
              "#FFDABE",
              "#F4ABD9",
              "#FEC9F8",
              "#B5CED2",
              "#CBDAD8",
              "#E6CBA8",
              "#D9D9D9",
              "#B3B3B3",

              "#FFA3A4",
              "#FEEA87",
              "#FEEDA6",
              "#ABD490",
              "#70D864",
              "#739FE8",
              "#71D2FF",
              "#D4BEE7",
              "#B993E8",
              "#FEC17E",
              "#FFD0AA",
              "#F39CE1",
              "#FFB4F7",
              "#AAC7CB",
              "#C2D9D3",
              "#E3BA9C",
              "#D1D1D1",
              "#ABABAB",

              "#FF9293",
              "#FEE777",
              "#FFE696",
              "#A4CE85",
              "#61D357",
              "#6B93E4",
              "#61CDFE",
              "#C9B0EA",
              "#AE86E6",
              "#FEB96E",
              "#FFBD8D",
              "#EE90DE",
              "#FCA3F6",
              "#A1C0C3",
              "#B7D2CB",
              "#DEB291",
              "#C8C8C8",
              "#A2A2A2",

              "#FF8184",
              "#FCE465",
              "#FFE189",
              "#9BCC73",
              "#59D148",
              "#5A89E1",
              "#4FC8FF",
              "#BFA4E2",
              "#A477E5",
              "#FFB55B",
              "#FFB2A1",
              "#E981DF",
              "#FE93F3",
              "#96B9BF",
              "#ADCBC3",
              "#DBA97D",
              "#BFBFBF",
              "#9A9A9A",

              "#FD726F",
              "#FCE352",
              "#FFDA78",
              "#91C66B",
              "#4ACC39",
              "#487FE1",
              "#40C2FE",
              "#B994E5",
              "#9A67E7",
              "#FFA850",
              "#FFAA6A",
              "#E874D1",
              "#FF81F5",
              "#8FB0B7",
              "#A1C5BB",
              "#DA9E72",
              "#B7B7B7",
              "#919191",

              "#FE5F60",
              "#FFDE45",
              "#FFD964",
              "#88C25A",
              "#3FC433",
              "#3B74DF",
              "#2FBDFF",
              "#AE87E0",
              "#905BDD",
              "#FEA139",
              "#FF9F5A",
              "#E664D1",
              "#FC71F4",
              "#80ABB1",
              "#96BEB3",
              "#D39667",
              "#AEAEAE",
              "#898989",

              "#FC504E",
              "#FFDA33",
              "#FFD552",
              "#81BB57",
              "#3AB630",
              "#3069DA",
              "#1DB7FF",
              "#A67ADC",
              "#8A4CDB",
              "#FF992A",
              "#FF9549",
              "#E455CB",
              "#FF60ED",
              "#78A3AA",
              "#8DB9AC",
              "#CF8E58",
              "#A6A6A6",
              "#808080",

              "#FF3D3D",
              "#FED823",
              "#FFD140",
              "#78B649",
              "#37A92D",
              "#2461D4",
              "#0CB2FF",
              "#9C6CD9",
              "#7E3ED8",
              "#FE9118",
              "#FF8B36",
              "#E246CB",
              "#FB50EC",
              "#6B9DA6",
              "#7FB4A4",
              "#CC834C",
              "#9D9D9D",
              "#787878",

              "#FF2B2B",
              "#FFD510",
              "#FECE2C",
              "#6DAB43",
              "#349A2C",
              "#205BC2",
              "#00ADFC",
              "#9061D2",
              "#7331DA",
              "#FD8908",
              "#FF8226",
              "#E237C4",
              "#FE3EE9",
              "#5E979E",
              "#75AD9C",
              "#C97A40",
              "#959595",
              "#6F6F6F",

              "#FF181B",
              "#F5D505",
              "#FEC821",
              "#679F3F",
              "#2E8E23",
              "#1E55BA",
              "#00A0E9",
              "#8952D4",
              "#692CC9",
              "#F97D00",
              "#FF7413",
              "#DF28C2",
              "#FA2EEF",
              "#588C97",
              "#6EA396",
              "#BE7437",
              "#8C8C8C",
              "#5E5E5E",

              "#FD0A0E",
              "#EBC500",
              "#FFC40E",
              "#60923B",
              "#2B8025",
              "#1E4DA9",
              "#0095D6",
              "#7F45CB",
              "#6627BF",
              "#E37800",
              "#FF6D06",
              "#D322B6",
              "#FC1DE8",
              "#55818A",
              "#639D8E",
              "#AF6C33",
              "#848484",
              "#000000",
            ],
          },
        ],
        imageBrowser: imageBrowser &&
          imageBrowser.enable && {
            messages: {
              dropFilesHere: "Drop files here",
            },
            transport: {
              read: imageBrowser.read,
              destroy: {
                url: imageBrowser.delete,
                type: "POST",
              },
              create: {
                url: "",
                type: "POST",
              },
              thumbnailUrl: imageBrowser.thumbnail,
              uploadUrl: imageBrowser.upload,
              imageUrl: imageBrowser.image,
            },
          },
        select: function (e) {
          $("#hdn-" + name).val(this.value());
          if (required && onValidate) {
            onValidate(name, this.value());
          }
          if (required && !onValidate) {
            console.error(`function onValidate missing for field "${name}"`);
          }
          if (onCursorPositionChange) {
            onCursorPositionChange(e.sender.getSelection().focusOffset);
          }

          if (onChange) {
            onChange(this.value());
          }
        },
        keyup: function (e) {
          $("#hdn-" + name).val(this.value());
          if (required && onValidate) {
            onValidate(name, this.value());
          }
          if (required && !onValidate) {
            console.error(`function onValidate missing for field "${name}"`);
          }

          if (onChange) {
            onChange(this.value());
          }
        },
        value: value || "",
      });
    }
  }, []);

  useEffect(() => {
    $(`#${guid}-${name}`).data("kendoEditor").value(value);
    $("#hdn-" + name).val(value);
  }, [value, refreshWhen]);

  return (
    <div className="k-editor-div">
      <textarea
        id={"hdn-" + name}
        name={name}
        style={{ display: "none" }}
        data-required={required}
        defaultValue={value || ""}
      ></textarea>
      <textarea
        id={`${guid}-${name}`}
        rows={10}
        cols={30}
        style={{ height: height }}
      ></textarea>
    </div>
  );
};

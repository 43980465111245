import React from "react";
import PropTypes from "prop-types";

const JobDetailsModal = ({ opportunity }) => {
  if (!opportunity) return null;
  function setBreak(summary) {
    return (summary ?? "")
      .split("\n")
      .map((line, index) => (index === 0 ? line : [<br key={index} />, line]));
  }

  return (
    <div>
      <div className="job-title">{opportunity.position}</div>
      <div className="job-location">{opportunity.location}</div>

      <div className="job-type">{opportunity.empType}</div>
      <p>
        <b>Work Place Type:</b>
        {opportunity.workType}
      </p>
      <p>
        <b>Contract Term:</b> {opportunity.contractTerm}
      </p>
      <p>
        <b>Summary:</b> <span>{setBreak(opportunity.summary)}</span>
      </p>
      <p>
        <b>Indicative Experience:</b> {opportunity.experience}
      </p>
      <p>
        <b>Required Skills for the position:</b>
      </p>
      <p className="Oppo-skills">
        <span
          dangerouslySetInnerHTML={{ __html: opportunity.requiredSkills }}
        />
      </p>
    </div>
  );
};

JobDetailsModal.propTypes = {
  opportunity: PropTypes.shape({
    opportunityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string.isRequired,
    location: PropTypes.string,
    empType: PropTypes.string,
    workType: PropTypes.string,
    contractTerm: PropTypes.string,
    summary: PropTypes.string,
    experience: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default JobDetailsModal;

import React, { useEffect, useState } from "react";

const HomeBody = () => {
  return (
    <>
      <section
        id="about"
        className="about-us-area section-padding"
        style={
          {
            // marginTop: "20px",
            // paddingTop: "20px",
            // paddingBottom: "50px",
            // textAlign: "center",
          }
        }
      >
        {" "}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title">
                <h2 className="Abouth2">About us</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* START ABOUT US TEXT DESIGN AREA */}
            <div className="col-md-12 text-center">
              <div className="about-text wow fadeInUp" data-wow-delay=".2s">
                <h2>
                  Transform IT with <span className="PtagBold">P</span>recision,
                  <span className="PtagBold">E</span>fficiency, and{" "}
                  <span className="PtagBold">N</span>imbleness – Experience{" "}
                  <span className="PtagBold">PEN</span> Software
                </h2>
                <p>
                  Much like the atom's proton, electron, and neutron, we power
                  the digital era with innovative solutions.
                </p>
                <p>
                  Our expertise spans , Digital Transformation, Oracle
                  Consulting, SAP Consulting, Professional Staffing, and
                  tailored IT solutions, revolutionizing business operations for
                  our clients.
                </p>
                {/* <p> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentiallyIt has survived not only five centuriesLorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                <a href="/OurUniverse" className="read-more">
                  Read more
                </a>
              </div>
            </div>
            {/* / END ABOUT US TEXT DESIGN AREA */}
          </div>
          {/* START FUN FACTS DESIGN AREA */}
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="single-project-complete single-project-border">
                <div className="plan-title">
                  <h4>OUR TEAM</h4>
                </div>
                <span className="lnr lnr-thumbs-up" />
                <h2 className="counter-num">100</h2>
                <span className="plus">+</span>
                <p>
                  World-class developers, project managers, business analysts,
                  UX/UI designers and more. 100+ Qualified experts
                </p>
                {/* <h6 class="text-muted">project completed</h6> */}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="single-project-complete single-project-border">
                <div className="plan-title">
                  <h4>OUR STORY</h4>
                </div>
                <span className="lnr lnr-smile" />
                <h2 className="counter-num">12</h2>
                <span className="plus">+</span>
                {/* <h6 class="text-muted">Happy Clients</h6> */}
                <p>
                  Founded in 2011, we've delivered more than 50 projects,
                  continuously growing and improving with our clients. 12+ Years
                  in business
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="single-project-complete single-project-border">
                <div className="plan-title">
                  <h4>HIRING</h4>
                </div>
                <span className="lnr lnr-code" />
                <h2 className="counter-num">750</h2>
                <span className="plus">+</span>
                {/* <h6 class="text-muted">Line Of Coding</h6> */}
                <p>
                  Filled positions across the globe and diverse technology
                  stack. 750+ Hired
                </p>
              </div>
            </div>
          </div>
          {/* /END FUN FACTS DESIGN AREA */}
        </div>
      </section>
      <div className="why-chhose-us-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="sectionTitileHeader">
                  The Big Bang: The Evolution of PEN Software
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="landing-image">
                <img src="/assets/images/landing-image.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12 wow fadeInUp" data-wow-delay=".2s">
                  <div className="single-about">
                    <div className="about-icon">
                      <span className="lnr lnr-sun" />
                    </div>

                    <p className="justify">
                      Founded in 2011, PEN Software was established to support
                      businesses in need of ERP and CRM solutions, providing
                      them with the essential accelerators for growth and
                      efficiency.
                    </p>
                  </div>
                </div>

                <div className="col-md-12  wow fadeInUp" data-wow-delay=".4s">
                  <div className="single-about">
                    <div className="about-icon">
                      <span className="lnr lnr-pie-chart" />
                    </div>

                    <p className="justify">
                      Our mission is to sustain, maintain, and upgrade these
                      implemented solutions, ensuring seamless transitions and
                      preventing the inheritance of existing problems through
                      our strategic upgrade approach.
                    </p>
                  </div>
                </div>

                <div className="col-md-12 wow fadeInUp" data-wow-delay=".6s">
                  <div className="single-about">
                    <div className="about-icon">
                      <span className="lnr lnr-crop" />
                    </div>

                    <p className="justify">
                      Building on our initial success, we ventured into the
                      expansive field of Digital Transformation. Today, we offer
                      bespoke, boutique solutions that address a wide array of
                      IT challenges, propelling businesses into new frontiers of
                      digital excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="call-to-action-area"
        data-stellar-background-ratio="0.6"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Our Approach</h2>
              <p>
                We customize solutions tailored to your needs, co-create with
                you, and provide ongoing support to ensure success. At every
                step, we tap into our industry expertise and invite you into our
                innovation ecosystem—a network that cultivates faster, fresher,
                and more substantial outcomes
              </p>
              {/* <a href="#" class="read-more white-read-more">Purchase now</a> */}
            </div>
          </div>
        </div>
      </section>
      <section id="service" className="service-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title">
                <h2>our services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* START SINGLE SERVICES DESIGN AREA */}
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="single-service text-center">
                <span className="lnr lnr-camera" />
                <h4>Digital Transformation</h4>
                <p>
                  Unlocking digital potential through tailored solutions,data
                  optimization, and modernization strategies
                </p>
              </div>
            </div>
            {/* / END SINGLE SERVICES DESIGN AREA */}
            {/* START SINGLE SERVICES DESIGN AREA */}
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="single-service text-center">
                <span className="lnr lnr-laptop" />
                <h4>Oracle Consulting</h4>
                <p>
                  Delivering personalized enterprise product support and
                  prioritizing business outcomes for Oracle product lines
                </p>
              </div>
            </div>
            {/* / END SINGLE SERVICES DESIGN AREA */}
            {/* START SINGLE SERVICES DESIGN AREA */}
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="single-service text-center">
                <span className="lnr lnr-keyboard" />
                <h4>SAP Consulting</h4>
                <p>
                  Expert guidance for seamless SAP application development,
                  Implementation, integration and optimization
                </p>
              </div>
            </div>
            {/* / END SINGLE SERVICES DESIGN AREA */}
            {/* START SINGLE SERVICES DESIGN AREA */}
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="single-service text-center">
                <span className="lnr lnr-earth" />
                <h4>Professional Staffing</h4>
                <p>
                  Su Misura – The made to fit experience tailored to your
                  evolving IT staffing needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeBody;
